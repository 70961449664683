import React from 'react';
import { Link } from 'react-router-dom'
// import coupleImg1 from '../../images/couple/2.jpg'
import coupleImg1 from '../../images/couple/dennis_336_2.jpg'
// import coupleImg2 from '../../images/couple/3.jpg'
import coupleImg2 from '../../images/couple/enrique_336.jpg'

import shape1 from '../../images/couple/shape-1.png'
import shape2 from '../../images/couple/shape-2.png'
import shape3 from '../../images/couple/shape-3.png'
import shape4 from '../../images/couple/shape-4.png'



const CoupleSection = (props) => {

    return (
    
                   
       



  <section className={`couple-section section-padding2 ${props.cClass}`} id="couple">
            <div className="container">


            <section className="story-section section-padding4" id="bienvenida">
         
     
         <div className="col col-xs-12">
         <div className="story-timeline">
         <div className="col col-lg-12 col-12 order-lg-1 order-2">
                         <div className="story-text3">
                     
                             <h3> Eres parte importante de nuestra vida, por lo que queremos celebrar este momento tan especial con tu presencia  </h3>         
                             <br/>                                
                             <h4>Uniremos Nuestro Amor en Sagrado Matrimonio</h4>
                         </div>
                     </div>
                     </div>
                     </div>
                     </section>


            
                <div className="row align-items-center justify-content-center">

                    <div className="col col-lg-11">
                        <div className="couple-area clearfix">
                            <div className="couple-item bride">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="couple-img">
                                            <img src={coupleImg1} alt="" />
                                            <div className="couple-shape">
                                                <img src={shape3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="couple-text">
                                            <h3>Dennis Eljure</h3>
                                            <p>Prometo amarte ahora y siempre, prometo nunca olvidar que este amor es para toda la vida y lo afortunados que somos de habernos encontrado.</p>
                                            {/* <div className="social">
                                                <ul>
                                                    <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                    <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                                                    <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="couple-item groom">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 order-lg-1 order-2">
                                        <div className="couple-text">
                                            <h3>Enrique Rodriguez</h3>
                                            <p> No hay mayor bendición que caminar en esta vida con un ser tan maravillosa como tu. Con la Bendición de Dios prometo dar lo mejor para nosotros y nuestra familia.</p>
                                            {/* <div className="social">
                                                <ul>
                                                    <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                    <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                                                    <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 order-lg-2 order-1">
                                        <div className="couple-img">
                                            <img src={coupleImg2} alt="" />
                                            <div className="couple-shape">
                                                <img src={shape4} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={shape1} alt="" />
            </div>
            <div className="shape-2">
                <img src={shape2} alt="" />


                </div>    
           
        </section>
        
    );
}



export default CoupleSection;