import React from 'react'
import {Link} from 'react-router-dom'
import shape1 from '../../images/rsvp/shape1.png'
import shape2 from '../../images/rsvp/shape2.png'
import SectionTitle from '../SectionTitle'
import wImg from '../../images/sobre2.jpg'
import banco from '../../images/banco.jpg'
import wImg2 from '../../images/wedding/1.jpg'
import wImg3 from '../../images/wedding/2.jpg'

const CtaSection = (props) => {
    return(
        
        
           



            <section className="story-section section-padding2" id="sobres">
         
     
         <div className="wpo-cta-section">
                <div className="conatiner-fluid">
                    <div className="row">
                        <div className="wpo-cta-item">
                            <h2>Lluvia de Sobres</h2>
                            {/* <Link to="/home" className="theme-btn-s2">Join With Us</Link> */}
                        </div>                        
                    </div>
                </div>            
            </div>




            
                    <div className="col col-xs-12">
                        <div className="story-timeline">


                            <div className="col col-lg-12 col-12 order-lg-1 order-2">
                                        <div className="story-text3">
                                            <h3> Nuestro mejor regalo es contar con tu presencia en este dia especial,
                                                pero si quieres hacernos un obsequio nos encantaria que sea:

                                                </h3>
                                            {/* <h4>Dios nos ha bendecido y contamos con lo necesario en nuestro hogar y matrimonio</h4> */}
                                            {/* <span className="date">Si deseas hacernos un regalo </span> */}
                                            {/* <div className="line-shape">
                                                <div className="outer-ball">
                                                    <div className="inner-ball"></div>
                                                </div>
                                            </div> */}
                                            {/* <p>Lorem ipsum dolor sit amet, constetur adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor vel duis.</p> */}
                                        </div>
                                    </div>


                        </div>
                    </div>
                
                
            <div className="wpo-wedding-single-area section-padding3">
                <div className="container">
                    <div className="row">
                    <div className="offset-lg-2 col-lg-8 col-12">
                            <div className="wpo-wedding-single-wrap">
                                <div className="wpo-wedding-single-item">
                                    {/* <div className="wpo-wedding-single-main-img">
                                     */}
                                     <div className="offset-lg-3 offset-3 col-6  col-lg-6 col-md-6 col-sm-6 col-6">
                                        <img src={wImg} alt=""/>
                                    </div>
                                    {/* <div className="wpo-wedding-single-title">
                                        <h3>Wishing you lots of love and happiness.</h3>
                                    </div> */}
                                    <p align="center">La lluvia de sobres, es la tradicion de regalar dinero en efectivo a los novios en un sobre el dia del evento.</p>
                                    {/* <p>Hac nibh fermentum nisi, platea condimentum cursus velit dui. Massa volutpat odio
                                        facilisis purus sit elementum. Non, sed velit dictum quam. Id risus pharetra est, at
                                        rhoncus, nec ullamcorper tincidunt. Id aliquet duis sollicitudin diam, elit sit.</p> */}

                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>

                
                                <div className="wpo-wedding-single-area section-padding3">
                <div className="container">
                    <div className="row">
                    <div className="offset-lg-2 col-lg-8 col-12">
                            <div className="wpo-wedding-single-wrap">
                                <div className="wpo-wedding-single-item">
                                <p align="center">Si deseas hacer transferencia bancaria:<br/>Cuenta CLABE: 012 680 01534309494 3</p>
                                {/* <p align="center">Cuenta CLABE: 012 680 01534309494 3</p> */}

                                
                                {/* <div className="wpo-wedding-single-main-img"> */}
                                    <div className="offset-lg-4 offset-md-4  offset-sm-4  offset-3 col-6  col-lg-4 col-md-4 col-sm-5 col-4">
                                        <img src={banco} alt=""/>
                                    </div>
                                    {/* <div className="wpo-wedding-single-title">
                                        <h3>Wishing you lots of love and happiness.</h3>
                                    </div> */}
                                    
                                    {/* <p>Hac nibh fermentum nisi, platea condimentum cursus velit dui. Massa volutpat odio
                                        facilisis purus sit elementum. Non, sed velit dictum quam. Id risus pharetra est, at
                                        rhoncus, nec ullamcorper tincidunt. Id aliquet duis sollicitudin diam, elit sit.</p> */}

                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>




        

            <div className="shape-1">
                <div className="sticky-shape">
                    <img src={shape1} alt=""/>
                </div>
            </div>
            <div className="shape-2">
                <div className="sticky-shape">
                    <img src={shape2} alt=""/>
                </div>
            </div>
        </section>




                                
       
    )
}

export default CtaSection;