import React from 'react'
import LocationMap from '../Modal'
import SectionTitleS2 from '../SectionTitleS2'
import wImg from '../../images/vestimenta2.jpg'
import wImg2 from '../../images/wedding/1.jpg'
import wImg3 from '../../images/wedding/2.jpg'

const Events = [
    {
        title:'Codigo de Vestimenta',
        li1:'Formal - Etiqueta', 
        // li2:'Corbata, Vestid',
        //li3:'Ex-Hacienda Lagunillas',
    },
    // {
    //     title:'Coctail de Bienvenida',
    //     li1:'5:00 PM – 6:00 PM',
    //     li2:'Patio',
    //     li3:'Ex-Hacienda Lagunillas'
    // },
    // {
    //     title:'Recepcion',
    //     li1:'6:00 PM – 2:00 AM',
    //     li2:'Area de Carpa',
    //     li3:'Ex-Hacienda Lagunillas'
    // },
    // {
    //     title:'Wedding Party',
    //     li1:'Monday, 25 Sep, 2022 1:00 PM – 4:30 PM',
    //     li2:'Estern Star Plaza, Road 123, USA',
    //     li3:'+1 234-567-8910'
    // },

]

const EventSection2 = (props) => {
    return (
        
        <section className={`wpo-event-section2 section-padding ${props.eClass}`} id="vestimenta">
            <div className="container">
                {/* <SectionTitleS2 MainTitle={'Codigo de Vestimenta'} /> */}
                <div className='col'>
                                        {/* <div className="wpo-event-item">
                                            <div className="wpo-event-text">
                                                <h2>hola</h2>
                                                <ul>
                                                    <li>hola</li>
                                                    <li>hola</li>
                                                    <li>hola</li>
                                                    <li><LocationMap/></li>
                                                </ul>
                                            </div>
                                        </div> */}
                <div className="wpo-event-wrap">
           
                    <div className="col">
                            
                                        

                            {Events.map((event, eitem) => (
                                <div className="col col-lg-12 col-md-12 col-12" key={eitem}>
                                    

                                        <div className="wpo-event-item">
                                            <div className="wpo-event-text">
                                                <h2>{event.title}</h2>
                                                <ul>
                                                    <h3>{event.li1}</h3>
                                                    <h1>{event.li2}</h1>
                                                    <li>{event.li3}</li>
                                                    {/* <li><LocationMap/></li> */}
                                                    <div className="offset-lg-5 offset-md-4  offset-sm-4 offset-4 col-lg-2 col-md-3 col-sm-3 col-3">
                                                        <div className="wpo-p-details-img">
                                                            <img src={wImg} alt=""/>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>

                            
                                    
                                </div>
                            ))}
                        </div>
                        
                    </div>

                    {/* <div className="row mt-4">
                                        <div className="col-6  col-lg-4 col-md-6 col-sm-6 col-6">
                                            <div className="wpo-p-details-img">
                                                <img src={wImg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src={wImg3} alt=""/>
                                            </div>
                                        </div>
                                    </div> */}


                  
                </div>
            </div>
            {/* <div className="offset-lg-5 offset-md-4  offset-sm-4 offset-4 col-lg-2 col-md-3 col-sm-3 col-3">
                                            <div className="wpo-p-details-img">
                                                <img src={wImg} alt=""/>
                                            </div>
                                        </div> */}
        </section>
         
            

    )
}

export default EventSection2;