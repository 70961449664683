import React from 'react'
import LocationMap from '../Modal'
import SectionTitleS2 from '../SectionTitleS2'


const Events = [
    {
        title:'Hacienda Lagunillas',
        // li1:'Sabado 10 d Junio ', 
        li2:'Manuel Gómez Morín 1440, Lagunillas',
        li3:'Queretaro',
    },
    // {
    //     title:'Coctail de Bienvenida',
    //     li1:'5:00 PM – 6:00 PM',
    //     li2:'Patio',
    //     li3:'Ex-Hacienda Lagunillas'
    // },
    // {
    //     title:'Recepcion',
    //     li1:'6:00 PM – 2:00 AM',
    //     li2:'Area de Carpa',
    //     li3:'Ex-Hacienda Lagunillas'
    // },
    // {
    //     title:'Wedding Party',
    //     li1:'Monday, 25 Sep, 2022 1:00 PM – 4:30 PM',
    //     li2:'Estern Star Plaza, Road 123, USA',
    //     li3:'+1 234-567-8910'
    // },

]

const EventSection = (props) => {
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="event">
            <div className="container">
                <SectionTitleS2 MainTitle={'Se Parte de Nuestra Historia'} />
                <div className='col'>
                                        {/* <div className="wpo-event-item">
                                            <div className="wpo-event-text">
                                                <h2>hola</h2>
                                                <ul>
                                                    <li>hola</li>
                                                    <li>hola</li>
                                                    <li>hola</li>
                                                    <li><LocationMap/></li>
                                                </ul>
                                            </div>
                                        </div> */}
                <div className="wpo-event-wrap">
                    <div className="col">
                            
                                        

                            {Events.map((event, eitem) => (
                                <div className="col col-lg-12 col-md-12 col-12" key={eitem}>
                                    

                                        <div className="wpo-event-item">
                                            <div className="wpo-event-text">
                                                <h2>{event.title}</h2>
                                                <ul>
                                                    <li>{event.li1}</li>
                                                    <li>{event.li2}</li>
                                                    <li>{event.li3}</li>
                                                    <li><LocationMap/></li>
                                                </ul>
                                            </div>
                                        </div>

                            
                                    
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;