import React from 'react';
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/1.jpg'
import sImg2 from '../../images/story/2.jpg'
import sImg3 from '../../images/story/3.jpg'

import shape1 from '../../images/rsvp/shape1.png'
import shape2 from '../../images/rsvp/shape2.png'

const StorySection = (props) => {

{/* <div className="col offset-lg-6 col-lg-6 col-12 text-holder"> */}



    return (
        <section className="story-section section-padding" id="story">
            <div className="container">
                <div className="row">
                    <SectionTitle MainTitle={'Itinerario'} />
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="row">
                                <div className="col offset-6 col-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-balloon"></i>
                                    </span>
                                </div>
                            </div>
                            
                            <div className="story-timeline-item s0">
                                <div className="row align-items-center">

                                    {/* <div className="col col-lg-6 col-12">
                                        <div className="img-holder right-align-text fadeInLeftSlow" data-wow-duration="1500ms">
                                            <img src={sImg1} className="img img-responsive" alt="stroy"/>
                                        </div>
                                    </div> */}
                                    <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder left-text">
                                        <div className="story-text right-align-text fadeInRightSlow" data-wow-duration="2000ms">
                                            <h3>Celebracion Religiosa</h3>
                                            <span className="date">4:00 pm - 5:00 pm</span>
                                            {/* <div className="line-shape">
                                                <div className="outer-ball">
                                                    <div className="inner-ball"></div>
                                                </div>
                                            </div> */}
                                            {/* <p>Lorem ipsum dolor sit amet, constetur adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor vel duis.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="story-timeline-item">
                                <div className="row align-items-center">
                                    <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                        <div className="story-text invisible">
                                            <h3>Cuadra fantasma</h3>
                                        </div>
                                    </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                        <div className="story-text left-align-text fadeInRightSlow" data-wow-duration="2000ms">
                                            <h3>Cocktail de Bienvenida</h3>
                                            <span className="date">5:00pm - 6:00 pm</span>
                                            {/* <div className="line-shape">
                                                <div className="outer-ball">
                                                    <div className="inner-ball"></div>
                                                </div>
                                            </div>
                                            <p>Lorem ipsum dolor sit amet, constetur adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor vel duis.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="story-timeline-item s3">
                                <div className="row align-items-center">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder left-text">
                                        <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">
                                            <h3>Recepcion</h3>
                                            <span className="date">6:00 pm - 12:00 am</span>
                                            {/* <div className="line-shape s2">
                                                <div className="outer-ball">
                                                    <div className="inner-ball"></div>
                                                </div>
                                            </div> */}
                                            <p>Grupo Versatil</p>
                                        </div>
                                    </div>
                                    {/* <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                        <div className="img-holder left-align-text">
                                            <img src={sImg2} alt="stroy" className="img img-responsive fadeInRightSlow" data-wow-duration="1500ms"/>
                                                <span className="heart">
                                                    <i className="fi flaticon-dance"></i>
                                                </span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="story-timeline-item s1">
                                <div className="row align-items-center">
                                    <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                        <div className="story-text invisible">
                                           <h3>Cuadra fantasma</h3>
                                        </div>
                                    </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                        <div className="story-text left-align-text fadeInRightSlow" data-wow-duration="2000ms">
                                            <h3>Tornaboda</h3>
                                            <span className="date">12:00 am - 2:00 am</span>
                                            {/* <div className="line-shape">
                                                <div className="outer-ball">
                                                    <div className="inner-ball"></div>
                                                </div>
                                            </div> */}
                                            <p>Banda Sinaloense</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <div className="sticky-shape">
                    <img src={shape1} alt=""/>
                </div>
            </div>
            <div className="shape-2">
                <div className="sticky-shape">
                    <img src={shape2} alt=""/>
                </div>
            </div>
        </section>
    )
}


export default StorySection;