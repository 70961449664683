import React from 'react';
import TimeCountDown from '../countdown';

const Hero5 = (props) => {
    // Estilos en línea para el botón base
    const buttonBaseStyles = {
        padding: '15px 30px',
        color: '#FFFFFF', // Cambié el texto a blanco para que resalte sobre colores oscuros
        fontSize: '20px', // Tamaño de letra más estándar
        fontFamily: 'Dancing Script, cursive',
        fontWeight: 'bold',
        border: 'none',
        borderRadius: '30px', // Bordes más redondeados
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        textDecoration: 'none',
        display: 'inline-block',
        margin: '20px', // Margen aumentado para más espacio
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Sombra más pronunciada
        position: 'relative',
        top: '0',
        // background: 'linear-gradient(135deg, rgba(255, 75, 75, 0.8), rgba(255, 195, 113, 0.8))', // Gradiente de fondo
        // backgroundColor: 'rgba(247, 202, 201, 0.9)' ,
        backgroundColor: 'rgba(255, 223, 186, 0.8)' ,
        border: '1px solid rgba(255, 255, 255, 0.5)', // Borde sutil
        backdropFilter: 'blur(10px)' // Filtro de desenfoque para un fondo borroso
    }

    // Función para manejar el clic del botón
    const handleButtonClick = (event) => {
        const buttonName = event.target.name;
        console.log(`${buttonName} button clicked!`);
    }

    // Función para manejar el clic del botón
    const handleButtonClickAlbum = (event) => {
        // URL de Google Drive que deseas abrir
        const url = 'https://drive.google.com/drive/folders/1EexJTRm9OMWoK8iCD_DJmMdc_hPJKUtF?usp=drive_link';
        
        // Abrir la URL en una nueva pestaña del navegador
        window.open(url, '_blank');
    }

        // Función para manejar el clic del botón
        const handleButtonClickVideo= (event) => {
            // URL de Google Drive que deseas abrir
            const url = 'https://drive.google.com/file/d/1KBirFu1vaJBD5L9lxDaE1d93uAcuuz-_/view?usp=sharing';
            
            // Abrir la URL en una nueva pestaña del navegador
            window.open(url, '_blank');
        }


    // Dado que ahora estamos usando un diseño de botón uniforme, podemos obviar los estilos específicos del botón

    return (
        <section className="wpo-hero-style-4">
            <div className="wedding-announcement">
                <div className="couple-text">
                    <h2>Dennis & Enrique</h2>
                    <p>Sábado 10 de Junio de 2023</p>
                    <div className="wpo-wedding-date">
                        {/* Contenido omitido para brevedad */}
                    </div>
                    {/* Botones */}
                    <button
                        style={buttonBaseStyles}
                        onClick={handleButtonClickAlbum}
                        name="ALBUM DE FOTOS"
                    >
                        ÁLBUM DE FOTOS
                    </button>
                    <button
                        style={buttonBaseStyles}
                        onClick={handleButtonClickVideo}
                        name="PELICULA"
                    >
                        PELICULA
                    </button>
                </div>
            </div>
            {/* Importación de Google Fonts y estilos para el hover */}
            <style>{`
                @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

                button:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                }

                button:active {
                    transform: translateY(1px);
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                }
            `}</style>
        </section>
    )
}

export default Hero5;