import React from 'react'
import VideoModal from '../ModalVideo'

// aqui va el video para dar click en reproducir en youtube
const VideoSection = (props) => {
    return(
        <section className="wpo-video-section">
            <h2 className="hidden">some</h2>
            {/* <VideoModal/> */} 
        </section>
    )
}

export default VideoSection;